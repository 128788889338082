import React from "react"
import Layout from "../components/layout/Layout"
import Cta from "../components/sections/Cta"
import Experience from "../components/sections/Experience"
import Banner from "../components/header/Banner"
import Services from "../components/services/Services"
import ProjectsList from "../components/projects/ProjectsList"
import Distributors from "../components/sections/Distributors"
import SEO from "../components/SEO"

export default () => {
  return (
    <Layout>
      <SEO title="Realizace" />
      <Banner
        heading="Realizace"
      />
      <ProjectsList />
      <Services page="page" />
      <Cta text="contact" />
      <Experience />
      <Distributors />
    </Layout>
  )
}
